<template>
  <b-overlay :show="!tableReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
    <b-row>
      <b-col cols="12" md="6" lg="4" xl="3" v-for="(item, index) in tempAllMethods" :key="index">
        <b-card class="border" no-body>
          <b-card-header class="p-1 bg-primary">
            <b-card-title
              class="
                font-medium-2
                w-100
                d-flex
                justify-content-between
                cursor-pointer
              "
              @click="item.open = !item.open"
            >
              <span class="align-middle ml-50 text-white">
                <feather-icon icon="LockIcon" size="18" class="mr-1" />
                {{ $t(item.module_name) }}
              </span>
              <feather-icon :icon="item.open ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="24" class="mr-1 text-white" />
            </b-card-title>
          </b-card-header>
          <b-card-body v-show="item.open" class="py-0">
            <div class="mt-1" v-if="item.pages.length > 0">
              <b-card-title
                class="
                  font-medium-2
                  w-100
                  d-flex
                  justify-content-between
                  border-bottom
                  cursor-pointer
                "
                @click="item.pOpen = !item.pOpen"
              >
                <span>
                  <feather-icon icon="BookIcon" size="16" class="mr-1" />
                  {{ $t("Pages") }}
                </span>
                <feather-icon :icon="item.pOpen ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="24" class="mr-1" />
              </b-card-title>
              <div v-show="item.pOpen" class="pl-2">
                <div
                  class="
                    d-flex
                    justify-content-between
                    border-bottom
                    mb-1
                    cursor-pointer
                  "
                  v-for="page in item.pages"
                  :key="page.method_id"
                >
                  <span @click="page.checked = !page.checked">
                    {{ $t(page.page_name) }}
                  </span>
                  <b-form-checkbox v-model="page.checked" />
                </div>
              </div>
            </div>
            <div class="mt-1" v-if="item.methods.length > 0">
              <b-card-title
                class="
                  font-medium-2
                  w-100
                  d-flex
                  justify-content-between
                  border-bottom
                  cursor-pointer
                "
                @click="item.mOpen = !item.mOpen"
              >
                <span>
                  <feather-icon icon="CpuIcon" size="16" class="mr-1" />
                  {{ $t("Methods") }}
                </span>
                <feather-icon :icon="item.mOpen ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="24" class="mr-1" />
              </b-card-title>
              <div v-show="item.mOpen" class="pl-2">
                <div
                  class="
                    d-flex
                    justify-content-between
                    border-bottom
                    mb-1
                    cursor-pointer
                  "
                  v-for="method in item.methods"
                  :key="method.method_id"
                >
                  <span @click="method.checked = !method.checked">
                    {{ $t(method.method_name) }}
                  </span>
                  <b-form-checkbox v-model="method.checked" />
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BOverlay, BFormCheckbox, BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
import { GET_ALL_METHODS } from "@/store/services/module-service";

export default {
  name: "user_page_perms",
  components: {
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    userMethods: { required: true, type: Array },
  },
  data() {
    return {
      allMethods: [],
      tempAllMethods: [],
      tableReady: false,
    };
  },
  created() {
    this.$store
      .dispatch(GET_ALL_METHODS)
      .then((response) => {
        this.allMethods = response;
      })
      .then(() => {
        this.reset();
      });
  },
  methods: {
    reset() {
      this.tableReady = false;
      this.tempAllMethods = [];
      this.allMethods.forEach((item) => {
        let findModule = this.tempAllMethods.findIndex((module) => module.module_name == item.module_name);
        if (findModule == -1) {
          this.tempAllMethods.push({
            module_name: item.module_name,
            pages: [],
            methods: [],
            open: false,
            pOpen: false,
            mOpen: false,
          });
          findModule = this.tempAllMethods.length - 1;
        }
        if (item.pages.length > 0) {
          item.pages.forEach((page) => {
            const findP = this.userMethods.findIndex((x) => x.method_id == page.method_id);
            let checkP = true;
            if (findP == -1) {
              checkP = false;
            }
            this.tempAllMethods[findModule].pages.push({
              method_id: page.method_id,
              page_name: page.method_name,
              checked: checkP,
            });
          });
        }
        if (item.methods.length > 0) {
          item.methods.forEach((method) => {
            const findM = this.userMethods.findIndex((x) => x.method_id == method.method_id);
            let checkM = true;
            if (findM == -1) {
              checkM = false;
            }
            this.tempAllMethods[findModule].methods.push({
              method_id: method.method_id,
              method_name: method.method_name,
              checked: checkM,
            });
          });
        }
      });
      this.tableReady = true;
    },
    getData() {
      const response = [];
      this.tempAllMethods.forEach((item) => {
        if (item.pages.length > 0) {
          item.pages.forEach((page) => {
            if (page.checked) {
              response.push({
                method_id: page.method_id,
              });
            }
          });
        }
        if (item.methods.length > 0) {
          item.methods.forEach((method) => {
            if (method.checked) {
              response.push({
                method_id: method.method_id,
              });
            }
          });
        }
      });
      return response;
    },
  },
};
</script>
