<template>
  <component :is="isProfile ? 'b-card' : 'div'">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="profilePhoto"
          :src="tempUserData.image_base_64 !== '' ? tempUserData.image_base_64 : require('@/assets/images/avatars/default.png')"
          text="User Photo"
          size="90px"
          badge
          variant="light-primary"
          class="user-badge"
          :badge-variant="tempUserData.is_passive ? 'danger' : 'success'"
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="selectPhoto()">
          <b-form-file ref="pickPhoto" accept="image/*" class="d-none" v-model="selectedPhoto" />
          <span class="d-none d-sm-inline">{{ $t('user_detail.update') }}</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1" @click="deletePhoto()">
          <span class="d-none d-sm-inline">{{ $t('user_detail.remove') }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Name -->
        <b-col cols="12" md="3" :xl="isProfile ? 3 : 2">
          <b-form-group :label="$t('user.name')" label-for="name">
            <validation-provider #default="{ errors }" :name="$t('user.name')" rules="required">
              <b-form-input id="username" v-model="tempUserData.name" :state="errors.length > 0 ? false : null" :placeholder="$t('user.name_placeholder')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: Surname -->
        <b-col cols="12" md="4" :xl="isProfile ? 3 : 2">
          <b-form-group :label="$t('user.surname')" label-for="surname">
            <validation-provider #default="{ errors }" :name="$t('user.surname')" rules="required">
              <b-form-input id="surname" v-model="tempUserData.surname" :state="errors.length > 0 ? false : null" :placeholder="$t('user.surname_placeholder')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4" :xl="isProfile ? 3 : 2">
          <b-form-group :label="$t('Mail')" label-for="email">
            <validation-provider #default="{ errors }" :name="$t('Mail')" rules="required|email">
              <b-form-input id="email" v-model="tempUserData.email" :state="errors.length > 0 ? false : null" type="email" :placeholder="$t('user.mail_placeholder')" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: Phone -->
        <b-col cols="12" md="3" :xl="isProfile ? 3 : 2">
          <b-form-group :label="$t('Phone')" label-for="phone">
            <validation-provider #default="{ errors }" :name="$t('Phone')" rules="required">
              <cleave id="phone" class="form-control" v-model="tempUserData.gsm_number" :options="cleaveOptions" :placeholder="$t('PhonePlaceholder')" :class="{ 'is-invalid': errors.length > 0 }" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col cols="12" md="3" xl="2" v-if="!isProfile">
          <b-form-group :label="$t('State')" label-for="user-state">
            <b-form-checkbox :checked="!tempUserData.is_passive" id="product-state" switch inline @change="changeState">
              {{ tempUserData.is_passive ? $t('Passive') : $t('Active') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </component>
</template>
<style lang="scss">
.user-badge {
  .b-avatar-badge {
    border: 2px solid white;
    min-width: 1em;
    min-height: 1em;
  }
}
</style>
<script>
import { BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormInvalidFeedback, BFormFile } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import { required } from '@validations'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import { SET_NOTIFICATION } from '@/store/services/module-service'
import { GET_IMAGE_BASE_64 } from '@/store/services/user-service'

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(data)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export default {
  name: 'user_account',
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormFile,

    ValidationProvider,
    Cleave
  },
  props: {
    userData: { required: true, type: Object },
    isProfile: { required: false, type: Boolean, default: false }
  },
  data() {
    return {
      required,
      tempUserData: {
        image_base_64: '',
        image_id: null,
        name: '',
        surname: '',
        email: '',
        gsm_number: '',
        is_passive: null
      },
      selectedPhoto: null,
      cleaveOptions: {
        phone: true,
        phoneRegionCode: 'TR'
      }
    }
  },
  created() {
    this.reset()
  },
  methods: {
    reset() {
      this.tempUserData.name = this.userData.name
      this.tempUserData.surname = this.userData.surname
      this.tempUserData.email = this.userData.email
      this.tempUserData.gsm_number = this.userData.gsm_number
      this.tempUserData.is_passive = this.userData.is_passive
      this.tempUserData.image_id = this.userData.image_id
      if (this.userData.image_id) {
        this.$store.dispatch(GET_IMAGE_BASE_64, this.userData.image_id).then(response => {
          this.tempUserData.image_base_64 = response
        })
      }
    },
    getData() {
      const response = {
        image_base_64: this.tempUserData.image_base_64,
        image_id: this.tempUserData.image_id,
        name: this.tempUserData.name,
        surname: this.tempUserData.surname,
        email: this.tempUserData.email,
        gsm_number: this.tempUserData.gsm_number
        // company_id: this.tempUserData.company_id.value,
      }
      if (!this.isProfile) {
        response.is_passive = this.tempUserData.is_passive
      }
      return response
    },
    selectPhoto() {
      this.$refs.pickPhoto.$el.childNodes[0].click()
    },
    deletePhoto() {
      this.tempUserData.image_base_64 = ''
      this.tempUserData.image_id = null
    },
    changeState(val) {
      this.tempUserData.is_passive = !val
    }
  },
  watch: {
    selectedPhoto(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          if (newValue.size < 512 * 1024) {
            // 512KB
            base64Encode(newValue)
              .then(value => {
                this.tempUserData.image_base_64 = value
                this.tempUserData.image_id = null
              })
              .catch(() => {
                this.tempUserData.image_base_64 = ''
              })
          } else {
            this.$store.commit(SET_NOTIFICATION, {
              title: this.$t('photo_max_size.title'),
              status: false,
              message: this.$t('photo_max_size.512_kb'),
              time: new Date()
            })
          }
        } else {
          this.tempUserData.image_base_64 = ''
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
