<template>
  <section class="basic-textarea">
    <div class="row">
      <div class="col-12">
        <b-card>
          <validation-observer ref="accountDefinition" tag="form" v-slot="{ invalid }">
            <b-tabs pills>
              <!-- Tab: Account -->
              <b-tab active>
                <template #title>
                  <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
                  <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
                </template>
                <user-account ref="account" :userData="userData" />
              </b-tab>

              <!-- Tab: Page Permissions -->
              <b-tab v-if="$can('manage', '/Method/GetAllMethods') && $can('manage', '/Method/GetUserMethods')">
                <template #title>
                  <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                  <span class="d-none d-sm-inline">
                    {{ $t('PagePermissions') }}
                  </span>
                </template>
                <user-page-perms ref="pagePerms" :userMethods="defaultPerms" />
              </b-tab>

              <!-- Tab: Report&Dashboard Permissions -->
              <b-tab v-if="$can('manage', '/Dashboard/GetAllDashboard') && $can('manage', '/Report/GetAllReport')">
                <template #title>
                  <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
                  <span class="d-none d-sm-inline">
                    {{ $t('Report&DashboardPermissions') }}
                  </span>
                </template>
                <user-report-and-dashboard-perms ref="reportAndDashboardPerms" :userReports="userData.user_report" :userDashboards="userData.user_dashboard" />
              </b-tab>

              <div @mouseenter="checkForm()">
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="newUser()" :disabled="invalid">
                  {{ $t('user_create.new_user') }}
                </b-button>
              </div>
            </b-tabs>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BButton } from 'bootstrap-vue'
import UserAccount from './components/UserAccount.vue'
import UserPagePerms from './components/UserPagePerms.vue'
import UserReportAndDashboardPerms from './components/UserReportAndDashboardPerms.vue'
import { CREATE_USER } from '@/store/services/user-service'
import { SET_NOTIFICATION } from '@/store/services/module-service'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'user-create',
  components: {
    BTab,
    BTabs,
    BLink,
    BCard,
    BAlert,
    BButton,

    ValidationObserver,

    UserPagePerms,
    UserAccount,
    UserReportAndDashboardPerms
  },
  data() {
    return {
      userData: {
        photo_url: null,
        name: '',
        surname: '',
        email: '',
        gsm_number: '',
        is_passive: false,
        user_method: [],
        user_report: [],
        user_dashboard: []
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'defaultPerms'])
  },
  methods: {
    newUser() {
      // Tüm Veriler Girilmiş
      const account = this.$refs.account.getData()
      let URD = undefined
      let UM = undefined
      if (this.$can('manage', '/Method/GetAllMethods') && this.$can('manage', '/Method/GetUserMethods')) {
        URD = this.$refs.reportAndDashboardPerms.getData()
      }
      if (this.$can('manage', '/Dashboard/GetAllDashboard') && this.$can('manage', '/Report/GetAllReport')) {
        UM = this.$refs.pagePerms.getData()
      }
      const tempPayload = {
        ...account,
        company_id: this.currentUser.company.company_id,
        user_method: UM,
        user_dashboard: URD?.user_dashboard,
        user_report: URD?.user_report
      }
      this.$store.dispatch(CREATE_USER, tempPayload).then(response => {
        this.$router.push({
          name: '/User/Detail',
          params: {
            id: response
          }
        })
      })
    },
    checkForm() {
      this.$refs.accountDefinition.validate()
    }
  }
}
</script>
