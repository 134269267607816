<template>
  <b-overlay
    :show="!tableReady"
    variant="dark"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-row class="d-flex justify-content-center">
      <b-col md="6" sm="12">
        <b-card no-body class="border mt-1" style="overflow-x: auto">
          <b-card-header class="p-1 bg-primary">
            <b-card-title class="font-medium-2 text-white">
              <feather-icon icon="PieChartIcon" size="18" />
              <span class="align-middle ml-50">
                {{ $t("ReportPermissions") }}
              </span>
            </b-card-title>
          </b-card-header>
          <table class="table table-striped w-100">
            <thead>
              <tr>
                <th>{{ $t("report_table.name") }}</th>
                <th>{{ $t("State") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="report in this.tempAllReports"
                :key="report.report_m_id"
              >
                <td>{{ report.report_name }}</td>
                <td>
                  <b-form-checkbox v-model="report.checked" />
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6" sm="12">
        <b-card no-body class="border mt-1" style="overflow-x: auto">
          <b-card-header class="p-1 bg-primary">
            <b-card-title class="font-medium-2 text-white">
              <feather-icon icon="ClipboardIcon" size="18" />
              <span class="align-middle ml-50">{{
                $t("DashboardPermissions")
              }}</span>
            </b-card-title>
          </b-card-header>
          <table class="table table-striped w-100">
            <thead>
              <tr>
                <th>{{ $t("dashboard.name") }}</th>
                <th>{{ $t("State") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="dashboard in this.tempAllDashboards"
                :key="dashboard.dashboard_m_id"
              >
                <td>{{ dashboard.dashboard_title }}</td>
                <td>
                  <b-form-checkbox v-model="dashboard.checked" />
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import { GET_ALL_REPORTS } from "@/store/services/report-service";
import { GET_ALL_DASHBOARD } from "@/store/services/dashboard-service";

export default {
  name: "user_report_and_dashboard_perms",
  components: {
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  props: {
    userReports: { required: true, type: Array },
    userDashboards: { required: true, type: Array },
  },
  data() {
    return {
      allReports: [],
      tempAllReports: [],
      allDashboards: [],
      tempAllDashboards: [],
      tableReady: false,
    };
  },
  created() {
    Promise.all([this.getAllReports(), this.getAllDashboards()]).then(() => {
      this.reset();
    });
  },
  methods: {
    getAllReports() {
      return new Promise((resolve) => {
        this.$store.dispatch(GET_ALL_REPORTS).then((response) => {
          this.allReports = response.data;
          resolve(true);
        });
      });
    },
    getAllDashboards() {
      return new Promise((resolve) => {
        this.$store.dispatch(GET_ALL_DASHBOARD).then((response) => {
          this.allDashboards = response.data;
          resolve(true);
        });
      });
    },
    reset() {
      this.tableReady = false;
      this.tempAllReports = [];
      this.tempAllDashboards = [];
      this.allReports.forEach((item) => {
        const checkIndex = this.userReports.findIndex(
          (x) => x.report_m_id == item.report_m_id
        );
        let checkR = false;
        if (checkIndex != -1) {
          checkR = true;
        }
        this.tempAllReports.push({ ...item, checked: checkR });
      });
      this.allDashboards.forEach((item) => {
        const checkIndex = this.userDashboards.findIndex(
          (x) => x.dashboard_m_id == item.dashboard_m_id
        );
        let checkD = false;
        if (checkIndex != -1) {
          checkD = true;
        }
        this.tempAllDashboards.push({ ...item, checked: checkD });
      });
      this.tableReady = true;
    },
    getData() {
      const user_report = [];
      const user_dashboard = [];
      this.tempAllReports.forEach((report) => {
        if (report.checked) {
          user_report.push({
            report_m_id: report.report_m_id,
          });
        }
      });
      this.tempAllDashboards.forEach((dashboard) => {
        if (dashboard.checked) {
          user_dashboard.push({
            dashboard_m_id: dashboard.dashboard_m_id,
          });
        }
      });
      return {
        user_report,
        user_dashboard,
      };
    },
  },
};
</script>
